import {
  ContentApplet,
  Content,
  Maybe,
  OtherContents,
  BlockContent,
  WeekScore,
} from '@innovamat/glow-api-client';
import { TFunction } from 'i18next';
import {
  getAppletReadableName,
  getFixedLocalizedScore,
  isNullOrUndefined,
} from './common';
import { getReportsTypography } from './typography';
import { getScoresHeights } from './getScoreHeights';
import { TimeSchedule } from '../views/weekly-results/weekly-results';
import BubbleSublabel from '../components/BubbleSublabel';
import Time from '../components/Time';
import TrainingZone from '../components/TrainingZone';
import Score from '../components/Score';
import LineChart from '../components/LineChart';
import {
  Typography,
  Row,
  Tooltip,
  SubLabel,
  InfoColumnIcon,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import AppletsWorkedOn from '../components/applets-worked-on';
import { truncateWords } from '@innovamat/radiance-utils';

type Translate = TFunction<'translation', undefined, 'translation'>;
export type TableType = 'weeklyResults' | 'generalProgress' | 'digitalPractice';
type MultipleProps = {
  values: MultipleColumn[];
  proportions: string;
};

type MultipleColumn = {
  component: JSX.Element;
  isHidden?: boolean;
};

type MultipleSublabels = {
  values: Sublabel[];
  proportions: string;
};

type Sublabel =
  | string
  | {
      text: string;
      tooltip?: string;
      infoTooltip?: string;
      isHidden?: boolean;
      dataTestId?: string;
    };

const renderEllipsableText = (
  value: string,
  row: Row,
  t: Translate,
  dataTestId?: string
): JSX.Element => {
  if (!row.firstName) return <p>{t('reports.averageClasroom.title.column')}</p>;
  return (
    <EllipsableText data-testid={dataTestId}>
      <p>{value}</p>
    </EllipsableText>
  );
};

const renderBubbles = (
  value: {
    average: number | null;
    applets: ContentApplet[];
    contents: BlockContent[];
  },
  contentAverage: Maybe<Maybe<ContentApplet | BlockContent>[]> | undefined,
  isExpanded: boolean,
  isSomeExpanded: boolean,
  dataTestId?: string
): JSX.Element => {
  const type = isExpanded ? 'underline' : 'box';
  if (!isExpanded) {
    if (!value) {
      return (
        <Score score={null} type={type} size="sm" hasOpacity={isSomeExpanded} />
      );
    }
    const { average } = value;
    return (
      <Score
        score={average === 0 ? 0 : average || null}
        type={type}
        size="sm"
        hasOpacity={isSomeExpanded}
        dataTestId={dataTestId}
      />
    );
  }

  return (
    <BubblesContainer>
      {contentAverage?.map((content: any, index: number) => {
        const { applets, contents } = value || {};
        let score = null;
        if (applets) {
          score = applets?.find((a) => a.uuid === content.uuid)?.score;
        } else if (contents) {
          score = contents?.find(
            (c) => c.contentId === content.contentId
          )?.score;
        }
        return (
          <InnerWrapper>
            <Score
              score={score === 0 ? 0 : score || null}
              type={type}
              size="sm"
              hasOpacity={isSomeExpanded}
              dataTestId={`${dataTestId}_${index + 1}`}
            />
          </InnerWrapper>
        );
      })}
    </BubblesContainer>
  );
};

const renderReviewBubbles = (
  value: OtherContents,
  isExpanded: boolean,
  isSomeExpanded: boolean,
  dataTestId?: string,
  isStudent?: boolean
) => {
  if (!value) return;
  const { score, contents } = value;
  const type = isExpanded ? 'underline' : 'box';
  if (score === 0)
    return (
      <Score score={0} type={type} size="sm" hasOpacity={isSomeExpanded} />
    );
  if (!isExpanded) {
    return (
      <Score score={score!} type={type} size="sm" hasOpacity={isSomeExpanded} />
    );
  }
  return (
    <BubblesContainer>
      {contents?.map((content: any) => {
        const contentTooltip = (): string => {
          let tooltipValues = '';
          content?.applets?.forEach((applet: any) => {
            tooltipValues += `${getAppletReadableName(
              applet!.data?.name,
              applet!.sceneName,
              applet!.pack
            )}: ${getFixedLocalizedScore(applet!.score!)} \n`;
          });

          return tooltipValues;
        };

        return (
          <InnerWrapper>
            <Tooltip
              content={
                <div style={{ whiteSpace: 'pre-line' }}>{contentTooltip()}</div>
              }
              popperOptions={{ strategy: 'fixed' }}
              disabled={!isStudent}
            >
              <Score
                score={content?.score!}
                type={type}
                size="sm"
                key={content?.score}
                dataTestId={dataTestId}
              />
            </Tooltip>
          </InnerWrapper>
        );
      })}
    </BubblesContainer>
  );
};

const renderCompletedTotal = (
  total: number,
  completed?: number,
  isError?: boolean,
  isDisabled?: boolean,
  dataTestId?: string
) => {
  return (
    <FlexContainerCentered
      isError={isError}
      isDisabled={isDisabled}
      data-testid={dataTestId}
    >
      <StyledP>{`${completed || '-'}/`}</StyledP>
      <Typography.Subtitle2>{total}</Typography.Subtitle2>
    </FlexContainerCentered>
  );
};

const getAverageScore = (array: any[]) => {
  const filteredArray = array.filter((el) => el.score !== null);
  if (!filteredArray.length) return null;
  return (
    filteredArray.reduce(function (acc, content) {
      return acc + content.score;
    }, 0) / filteredArray.length
  );
};

const renderOtherContents = (
  row: Row,
  otherContents: OtherContents,
  isExpanded: boolean,
  isSomeExpanded: boolean,
  dataTestId?: string
) => {
  if (!row.uuid) {
    return renderReviewBubbles(
      otherContents!,
      isExpanded,
      isSomeExpanded,
      dataTestId
    );
  }

  const contentsArray =
    otherContents.contents?.map((otherContent) =>
      row.blocks
        ? findContentInBlocks(row.blocks, otherContent!)
        : findContentInRow(row, otherContent!)
    ) ?? [];

  return renderReviewBubbles(
    { score: getAverageScore(contentsArray), contents: contentsArray },
    isExpanded,
    isSomeExpanded,
    dataTestId,
    true
  );
};

const findContentInBlocks = (blocks: any[], otherContent: Content) => {
  let foundContent = null;
  blocks.forEach((block) => {
    const content = block.contents?.find(
      (content: Content) => otherContent.contentId === content.contentId
    );
    if (content) foundContent = content;
  });
  return foundContent || { score: null, contentId: null };
};

const findContentInRow = (row: Row, otherContent: Content) => {
  return (
    row.contents?.find(
      (content: Content) => content.contentId === otherContent.contentId
    ) || { score: null, contentId: null }
  );
};

const renderSelectorAppletsSublabel = (t: Translate): JSX.Element => {
  return (
    <AppletSelectorContainer>
      <SublabelContainer>
        {t('reports.appletsSelector.sublabel.passed')}
      </SublabelContainer>
      <SublabelContainer>
        {t('reports.appletsSelector.sublabel.notPassed')}
      </SublabelContainer>
    </AppletSelectorContainer>
  );
};

const renderTrainingZoneSublabel = (t: Translate): JSX.Element => {
  return <SubLabel>{t('reports.trainingZone.sublabel')}</SubLabel>;
};

const renderBubblesSubLabels = ({
  applets,
  isExpanded,
  visibleAppletPreview,
  handleShowAppletPreview,
  t,
  handleSendEvent,
  stickyTable,
  isContents,
  id,
  handleNavigateToResource,
}: {
  applets: Maybe<Maybe<ContentApplet | BlockContent>[]>;
  isExpanded: boolean;
  visibleAppletPreview: string;
  handleShowAppletPreview: (sceneName: string, interacting?: boolean) => void;
  t: Translate;
  handleSendEvent: (event: string, parameters?: {}) => void;
  stickyTable?: boolean;
  isContents?: boolean;
  id?: string;
  handleNavigateToResource: (appletId: string) => void;
}) => {
  const titleType = isContents ? 'contents' : 'applets';
  if (!applets?.length)
    return `${t(`reports.${titleType}.title.column`, {
      num: 0,
    })}`;
  if (!isExpanded) {
    if (applets.length > 1) {
      return `${t(`reports.${titleType}.title.column`, {
        num: applets.length,
      })}`;
    } else {
      return `${t(`reports.${titleType}.title.column.singular`, {
        num: 1,
      })}`;
    }
  }
  return applets.map((applet) => {
    return (
      <BubbleSublabel
        applet={applet}
        handleShowAppletPreview={handleShowAppletPreview}
        visibleAppletPreview={visibleAppletPreview}
        id={id}
        t={t}
        isContents={isContents}
        stickyTable={stickyTable}
        handleSendEvent={handleSendEvent}
        handleNavigateToResource={handleNavigateToResource}
      />
    );
  });
};

const renderReviewSubLabels = (
  otherContents: Maybe<OtherContents> | undefined,
  isExpanded: boolean,
  t: Translate
) => {
  const { contents } = otherContents!;
  if (!contents?.length) return '';
  if (!isExpanded)
    return `${t(
      contents.length === 1
        ? 'reports.contents.title.column.singular'
        : 'reports.contents.title.column',
      { num: contents.length }
    )}`;
  return contents.map((content) => {
    const { contentTitle, contentTooltip, contentTextCode, skillTextCode } =
      content!;
    let toolTip;
    let sublabel;
    if (contentTitle && contentTooltip) {
      const { text, requiresTranslation } = contentTitle;
      const {
        text: tooltipText,
        requiresTranslation: requiresTooltipTranslation,
      } = contentTooltip;
      toolTip = requiresTooltipTranslation ? t(`${tooltipText}`) : tooltipText;
      sublabel = `${requiresTranslation ? t(`${text}`) : text}`;
    } else {
      const skillTextCodeText =
        !skillTextCode || skillTextCode === 'TR.'
          ? ''
          : `: ${t(skillTextCode)}`;
      toolTip = `${t(contentTextCode!)}${skillTextCodeText}`;
      sublabel = `${t(contentTextCode!)}${skillTextCodeText}`;
    }

    return (
      <BubblesSubLabelsWrapper>
        <Tooltip content={toolTip} popperOptions={{ strategy: 'fixed' }}>
          <div>{truncateWords(sublabel, 8)}</div>
        </Tooltip>
      </BubblesSubLabelsWrapper>
    );
  });
};

const renderTime = (
  row: Row,
  practicePeriod: TimeSchedule,
  tableType: TableType,
  isSecundaria: boolean,
  dataTestId?: string
): JSX.Element => {
  const { timeSpentHome, timeSpentSchool, timeSpentAll, firstName } = row;
  return (
    <Container tableType={tableType}>
      <Time
        timeSpentHome={timeSpentHome}
        timeSpentSchool={timeSpentSchool}
        timeSpentAll={timeSpentAll}
        practicePeriod={practicePeriod}
        dataTestId={dataTestId}
        tableType={tableType}
        isAverageRow={!firstName}
        isSecundaria={isSecundaria}
      />
    </Container>
  );
};

const renderScore = (
  value: number,
  dataTestId?: string,
  isNotRepresentativeScore?: boolean
): JSX.Element => {
  return (
    <Score
      score={value}
      type="box"
      size="lg"
      align="center"
      isNotRepresentativeScore={isNotRepresentativeScore}
      dataTestId={dataTestId}
    />
  );
};

const renderAppletsWorkedOn = (
  value: number,
  row: Row,
  isSecundaria?: boolean,
  practicePeriod?: TimeSchedule,
  dataTestId?: string
): JSX.Element => {
  return (
    <ContainerAppletsWorkedOn
      totalApplets={row.appletsServed}
      data-testid={dataTestId}
    >
      <AppletsWorkedOn
        completedStatements={value}
        completedApplets={row.appletsCompleted}
        totalApplets={row.appletsServed}
        isAverageRow={!row.firstName}
        showProgressBar={practicePeriod === 'all' && isSecundaria}
        isSecundaria={isSecundaria}
        totalStatements={row.statementsServed}
      />
    </ContainerAppletsWorkedOn>
  );
};

const renderTrainingZone = (row: Row, dataTestId: string) => {
  const trainingZone = row.trainingZone;
  if (!trainingZone) return;
  const { timeSpent, appletsImproved, appletsAttempted } = trainingZone;
  const { count, applets } = appletsAttempted || {};
  return (
    <Container>
      <TrainingZone
        timeApplets={timeSpent}
        appletsImproved={appletsImproved}
        appletsAttemptedCount={count}
        tooltipTrainingZone={applets}
        dataTestId={dataTestId}
      />
    </Container>
  );
};

const renderTraining = ({
  value,
  proportions,
  practicePeriod,
}: {
  value: {
    timeSpent: number;
    averageScore: number;
  };
  proportions: string;
  practicePeriod: TimeSchedule;
}): JSX.Element => {
  const { timeSpent, averageScore } = value;
  return renderMultipleColumns({
    values: [
      {
        component: (
          <FlexContainerCentered>
            <Time
              timeSpentHome={timeSpent}
              timeSpentSchool={timeSpent}
              timeSpentAll={timeSpent}
              practicePeriod={practicePeriod}
              tableType={'weeklyResults'}
              isProgressBarHidden
              isTrainingDedicationColumn
            />
          </FlexContainerCentered>
        ),
      },
      {
        component: (
          <FlexContainerCentered>
            <Score score={averageScore} type="underline" align="center" />
          </FlexContainerCentered>
        ),
      },
    ],
    proportions: proportions,
  });
};

const getVisibleProportions = (
  proportions: string,
  visibleValues: Sublabel[] | MultipleColumn[]
): string => {
  return proportions.split(' ').slice(0, visibleValues.length).join(' ');
};

const renderMultipleColumns = ({
  values,
  proportions,
}: MultipleProps): JSX.Element => {
  const visibleValues = values.filter((value) => !value.isHidden);
  return (
    <MultipleContainer
      proportions={getVisibleProportions(proportions, visibleValues)}
    >
      {visibleValues.map((value) => value.component)}
    </MultipleContainer>
  );
};

const renderMultipleSublabels = ({
  values,
  proportions,
}: MultipleSublabels): JSX.Element => {
  const visibleValues = values.filter(
    (value) => typeof value === 'string' || !value.isHidden
  );
  return (
    <MultipleContainer
      proportions={getVisibleProportions(proportions, visibleValues)}
    >
      {visibleValues.map((value) => renderSubLabel(value))}
    </MultipleContainer>
  );
};

const renderSubLabel = (sublabel: Sublabel): JSX.Element => {
  if (typeof sublabel === 'string') {
    return <Sublabel>{sublabel}</Sublabel>;
  }
  const { text, tooltip, infoTooltip, dataTestId } = sublabel;
  return (
    <Sublabel data-testid={dataTestId}>
      <Tooltip content={tooltip} popperOptions={{ strategy: 'fixed' }}>
        <div>{text}</div>
      </Tooltip>
      {infoTooltip && (
        <SublabelInfoIcon>
          <InfoColumnIcon tooltipText={infoTooltip} />
        </SublabelInfoIcon>
      )}
    </Sublabel>
  );
};

const renderAppletSelector = (row: Row, t: Translate): JSX.Element => {
  const { appletSelectorActivities } = row;
  const filterApplets = (passedStatus: boolean): ContentApplet[] =>
    appletSelectorActivities?.filter(
      (applet: ContentApplet) => applet?.passed === passedStatus
    ) || [];

  const passedApplets = filterApplets(true);
  const notPassedApplets = filterApplets(false);

  const renderAppletsLength = (applets: ContentApplet[]): JSX.Element =>
    applets.length !== 0 ? (
      <NumberOfApplets>
        {applets.length}
        <Suffix>{t('reports.appletsDone.cell')}</Suffix>
      </NumberOfApplets>
    ) : (
      <NoApplets>-</NoApplets>
    );

  const createAppletsTooltip = (applets: ContentApplet[]): string => {
    return (
      applets
        ?.map(
          (applet: ContentApplet) =>
            `${getAppletReadableName(
              applet.data?.name,
              applet.sceneName!,
              applet.pack!
            )}`
        )
        .join(',\n') || ''
    );
  };

  const passedAppletsTooltip = (): string => {
    return createAppletsTooltip(passedApplets);
  };

  const notPassedAppletsTooltip = (): string => {
    return createAppletsTooltip(notPassedApplets);
  };

  return (
    <AppletSelectorContainer>
      <SpacedContainer data-testid={`WERE_TABLE_APPSEL_WELL_S${row.uuid}`}>
        <Tooltip
          content={passedAppletsTooltip()}
          popperOptions={{ strategy: 'fixed' }}
        >
          {renderAppletsLength(passedApplets)}
        </Tooltip>
      </SpacedContainer>
      <SpacedContainer data-testid={`WERE_TABLE_APPSEL_WRONG_S${row.uuid}`}>
        <Tooltip
          content={notPassedAppletsTooltip()}
          popperOptions={{ strategy: 'fixed' }}
        >
          {renderAppletsLength(notPassedApplets)}
        </Tooltip>
      </SpacedContainer>
    </AppletSelectorContainer>
  );
};

const renderSublabelWeeklyProgress = (
  weeklyProgress: Maybe<Maybe<WeekScore>[]> | undefined,
  t: Translate
): JSX.Element => {
  const numberOfWeeks = weeklyProgress?.length ?? 0;
  const sublabelText =
    numberOfWeeks >= 6
      ? `${t('reports.lastWeeks.title.column', { weeks: 6 })}`
      : `${t('reports.numberOfweek.title.column', { numberOfWeeks })}`;

  return <SubLabel>{sublabelText}</SubLabel>;
};

const renderWeeklyProgress = (
  weeklyProgress: WeekScore[],
  handleClickDataPoint: (dataPoint: { from: string; to: string }) => void,
  dataTestId: string
): JSX.Element => {
  const numElements = weeklyProgress.length >= 6 ? 6 : weeklyProgress.length;
  const recentProgress =
    weeklyProgress.length > 6 ? weeklyProgress.slice(-6) : weeklyProgress;
  const scores = [] as number[];
  const startDate = [] as string[];
  const endDate = [] as string[];

  recentProgress.forEach(({ score, weekStartDate, weekEndDate }) => {
    scores.push(score!);
    startDate.push(weekStartDate);
    endDate.push(weekEndDate);
  });
  const scoresHeights = getScoresHeights(scores, numElements);

  return (
    <LineChart
      scores={scores}
      scoresHeights={scoresHeights}
      startDate={startDate}
      endDate={endDate}
      width={164}
      dotsLeft={scores.length >= numElements}
      handleClickDataPoint={handleClickDataPoint}
      dataTestId={dataTestId}
    />
  );
};

export {
  renderEllipsableText,
  renderBubbles,
  renderSelectorAppletsSublabel,
  renderBubblesSubLabels,
  renderTime,
  renderScore,
  renderTrainingZone,
  renderTraining,
  renderAppletSelector,
  renderReviewSubLabels,
  renderOtherContents,
  renderTrainingZoneSublabel,
  renderSublabelWeeklyProgress,
  renderWeeklyProgress,
  renderCompletedTotal,
  renderAppletsWorkedOn,
  renderMultipleSublabels,
};

export const TableContainer = styled.div`
  position: relative;
  display: grid;
`;

export const BubblesContainer = styled.div`
  display: flex;
  margin-right: 18px;
`;

export const BubblesSubLabelsWrapper = styled.span`
  width: 88px;
  margin: 0 4px;
`;

export const Text = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const InnerWrapper = styled.span`
  width: 88px;
  margin: 0 4px;
`;

export const Container = styled.div<{ tableType?: TableType }>`
  display: flex;
  height: 30px;
  ${({ tableType }) =>
    tableType === 'digitalPractice' && 'justify-content: center;'}
`;

export const ContainerAppletsWorkedOn = styled.div<{ totalApplets: number }>`
  display: flex;
  margin-left: 2px;
  align-items: center;
  ${({ totalApplets }) => {
    if (totalApplets === 0 || isNullOrUndefined(totalApplets)) {
      return `
      justify-content: center;
      margin-left: 0px;
    `;
    }
    return ``;
  }}
`;

export const EllipsableText = styled(Typography.Body2)`
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
  }
`;

export const SpacedContainer = styled.div`
  display: flex;
  gap: 8px;
  flex: 0 0 50%;
  cursor: default;
`;

export const SublabelContainer = styled.div`
  display: flex;
  gap: 8px;
  flex: 0 0 50%;
`;

export const AppletSelectorContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const AdaptedPlansContainer = styled.div``;

export const AdaptedPlansTitle = styled(Typography.H4)`
  margin-top: 40px;
`;

export const NoApplets = styled.div`
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getReportsTypography()}
`;

export const NumberOfApplets = styled.div`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text['text'].value};
  ${getReportsTypography()}
  flex: 0 0 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const Suffix = styled(Typography.Subtitle2)`
  display: inline;
  padding-left: 2px;
`;

export const PrintArea = styled.div``;

export const FlexContainerCentered = styled.div<{
  isError?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: baseline;
  & * {
    color: ${({ isError, theme }) =>
      isError ? theme.tokens.color.specific.reports.semantic.low.value : ''};
    color: ${({ isDisabled, theme }) =>
      isDisabled
        ? theme.tokens.color.specific.reports.semantic['no-data'].value
        : ''};
  }
`;

export const StyledP = styled.p`
  ${getReportsTypography()}
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
`;

const MultipleContainer = styled.div<{ proportions: string }>`
  display: grid;
  gap: 8px;
  grid-template-columns: ${({ proportions }) => proportions};
  width: 100%;
  align-items: center;
`;

const Sublabel = styled(Typography.Body3)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  cursor: default;
  position: relative;
  width: fit-content;
`;

const SublabelInfoIcon = styled.div`
  position: absolute;
  top: 0;
  right: 1px;
  transform: translate(100%, -50%);
`;
